<template>
  <b-card-code title="">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Archivált alkalmazottak listája</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/employee/create')">Alkalmazott létrehozás</button>
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/employee/list')">Aktív alkalmazottak</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'active'"
          class="text-nowrap"
        >
          <span class="text-nowrap" v-if="props.row.active == 1">Aktív</span>
          <span class="text-nowrap" v-else>Inaktív</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'salary'">
          <span>{{ parseInt(props.row.salary).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</span>
        </span>

        <span v-else-if="props.column.field === 'name'">
          <span style="cursor:pointer" @click="selectedData = props.row;$bvModal.show('employee_data');getCarrierData(props.row.tax_number)">{{ props.row.name }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="$router.push('/employee/edit/'+props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
              <b-dropdown-item @click="archiveThisUser(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Aktíválás</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="employee_data" title="Alkalmazott adatai" size="lg" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-col lg="12" class="mt-1">
          <b>Személyes adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Név: <b>{{ selectedData.name }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Lakcím: <b>{{ selectedData.address }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Anyja neve: <b>{{ selectedData.mother_name }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Születési hely: <b>{{ selectedData.birth_location }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Születési idő: <b>{{ selectedData.birth_date }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Telefonszám: <b>{{ selectedData.phone_number }}</b>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Okmány adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          TAJ szám: <b>{{ selectedData.health_insurance_number }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Adóazonosító  szám: <b>{{ selectedData.tax_number }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Szem.ig.szám: <b>{{ selectedData.personal_number }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Személyi szám (azonosító): <b>{{ selectedData.personal_number_sec }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Lakcímkártya száma: <b>{{ selectedData.address_of_residence }}</b>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Egyéb</b>
        </b-col>
        <b-col lg="12" class="mt-1">
          Megjegyzés: <b>{{ selectedData.comment }}</b>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Munka adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Aktív munkavállaló: <b v-if="selectedData.active">Aktív</b>
          <b v-else>inaktív</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Cég: <b>{{ selectedData.monolit }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Munkakör: <b>{{ selectedData.work }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          FEOR szám: <b>{{ selectedData.feor_number }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Munkaszerződés dátuma: <b>{{ selectedData.work_contract }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Munkakezdés dátuma: <b>{{ selectedData.work_start }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Bruttó bér: <b v-if="selectedData.salary != null">{{ parseInt(selectedData.salary).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Bruttó bér (szövegesen): <b>{{ selectedData.salary_text }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Éjszakai pótlék: <b>{{ selectedData.night_supplement }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Éjszakai pótlék (szövegesen): <b>{{ selectedData.night_supplement_text }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Csoport szám: <b>{{ selectedData.group_number }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Bankszámlaszám: <b>{{ selectedData.bank_number }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Utazási támogatás: <b>{{ selectedData.travel_support }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Munkaidő: <b>{{ selectedData.work_hour }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Kilépés ideje: <b>{{ selectedData.exit_time }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Egészségügyi alkalmassági: <b>{{ selectedData.medical_fitness }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Munkavédelmi oktatás: <b>{{ selectedData.OSH }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Szakmája: <b>{{ selectedData.profession }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Legmagasabb iskolai végzettség: <b>{{ selectedData.education }}</b>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Ruha méretek</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Ruha kiadás dátuma: <b>{{ selectedData.clothes_date }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Póló méret: <b>{{ selectedData.shirt_size }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Kabát méret: <b>{{ selectedData.top_clothes_size }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Eső kabát méret: <b>{{ selectedData.waterproof_size }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Nadrág méret: <b>{{ selectedData.bottom_clothes_size }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Bakancs méret: <b>{{ selectedData.boots_size }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Csizma méret: <b>{{ selectedData.shoes_size }}</b>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Családi adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Kapcsolatban: <b>{{ selectedData.partnership }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Baleset esetén értesítendő személy neve: <b>{{ selectedData.notified_person }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Rokoni kapcsolat megnevezése: <b>{{ selectedData.notified_person_family_relationship }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Értesítendő személy telefonszáma: <b>{{ selectedData.notified_person_phone }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Adó kedvezményt igényel: <b>{{ selectedData.tax_reducation }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Adó kedvezmény: <b>{{ selectedData.tax }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Adó kedvezmény összege: <b>{{ selectedData.tax_reduction_price }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Kiskorú gyermekek száma (szabadsághoz): <b>{{ selectedData.underage }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Közeli hozzátartozó meghalt idén: <b>{{ selectedData.close_relative_died }}</b>
        </b-col>
        <b-col lg="12" class="mt-1" v-if="selectedData.children_name_1 != null && selectedData.children_name_1 != ''">
          <hr>
          <b>1.) Gyerek adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_1 != null && selectedData.children_name_1 != ''">
          1. Gyermek neve: <b>{{ selectedData.children_name_1 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_1 != null && selectedData.children_name_1 != ''">
          1. Gyermek születési helye: <b>{{ selectedData.children_birth_place_1 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_1 != null && selectedData.children_name_1 != ''">
          1. Gyermek születési dátum: <b>{{ selectedData.children_birth_date_1 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_1 != null && selectedData.children_name_1 != ''">
          1. Gyermek TAJ szám: <b>{{ selectedData.children_health_insurance_number_1 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_1 != null && selectedData.children_name_1 != ''">
          1. Gyermek adószám: <b>{{ selectedData.children_tax_number_1 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_1 != null && selectedData.children_name_1 != ''">
          1. Gyermek anyja neve: <b>{{ selectedData.children_mother_name_1 }}</b>
        </b-col>
        <b-col lg="12" class="mt-1" v-if="selectedData.children_name_2 != null && selectedData.children_name_2 != ''">
          <hr>
          <b>2.) Gyerek adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_2 != null && selectedData.children_name_2 != ''">
          2. Gyermek neve: <b>{{ selectedData.children_name_2 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_2 != null && selectedData.children_name_2 != ''">
          2. Gyermek születési helye: <b>{{ selectedData.children_birth_place_2 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_2 != null && selectedData.children_name_2 != ''">
          2. Gyermek születési dátum: <b>{{ selectedData.children_birth_date_2 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_2 != null && selectedData.children_name_2 != ''">
          2. Gyermek TAJ szám: <b>{{ selectedData.children_health_insurance_number_2 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_2 != null && selectedData.children_name_2 != ''">
          2. Gyermek adószám: <b>{{ selectedData.children_tax_number_2 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_2 != null && selectedData.children_name_2 != ''">
          2. Gyermek anyja neve: <b>{{ selectedData.children_mother_name_2 }}</b>
        </b-col>
        <b-col lg="12" class="mt-1" v-if="selectedData.children_name_3 != null && selectedData.children_name_3 != ''">
          <hr>
          <b>3.) Gyerek adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_3 != null && selectedData.children_name_3 != ''">
          3. Gyermek neve: <b>{{ selectedData.children_name_3 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_3 != null && selectedData.children_name_3 != ''">
          3. Gyermek születési helye: <b>{{ selectedData.children_birth_place_3 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_3 != null && selectedData.children_name_3 != ''">
          3. Gyermek születési dátum: <b>{{ selectedData.children_birth_date_3 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_3 != null && selectedData.children_name_3 != ''">
          3. Gyermek TAJ szám: <b>{{ selectedData.children_health_insurance_number_3 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_3 != null && selectedData.children_name_3 != ''">
          3. Gyermek adószám: <b>{{ selectedData.children_tax_number_3 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_3 != null && selectedData.children_name_3 != ''">
          3. Gyermek anyja neve: <b>{{ selectedData.children_mother_name_3 }}</b>
        </b-col>
        <b-col lg="12" class="mt-1" v-if="selectedData.children_name_4 != null && selectedData.children_name_4 != ''">
          <hr>
          <b>4.) Gyerek adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_4 != null && selectedData.children_name_4 != ''">
          4. Gyermek neve: <b>{{ selectedData.children_name_4 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_4 != null && selectedData.children_name_4 != ''">
          4. Gyermek születési helye: <b>{{ selectedData.children_birth_place_4 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_4 != null && selectedData.children_name_4 != ''">
          4. Gyermek születési dátum: <b>{{ selectedData.children_birth_date_4 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_4 != null && selectedData.children_name_4 != ''">
          4. Gyermek TAJ szám: <b>{{ selectedData.children_health_insurance_number_4 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_4 != null && selectedData.children_name_4 != ''">
          4. Gyermek adószám: <b>{{ selectedData.children_tax_number_4 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_4 != null && selectedData.children_name_4 != ''">
          4. Gyermek anyja neve: <b>{{ selectedData.children_mother_name_4 }}</b>
        </b-col>
        <b-col lg="12" class="mt-1" v-if="selectedData.children_name_5 != null && selectedData.children_name_5 != ''">
          <hr>
          <b>5.) Gyerek adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_5 != null && selectedData.children_name_5 != ''">
          5. Gyermek neve: <b>{{ selectedData.children_name_5 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_5 != null && selectedData.children_name_5 != ''">
          5. Gyermek születési helye: <b>{{ selectedData.children_birth_place_5 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_5 != null && selectedData.children_name_5 != ''">
          5. Gyermek születési dátum: <b>{{ selectedData.children_birth_date_5 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_5 != null && selectedData.children_name_5 != ''">
          5. Gyermek TAJ szám: <b>{{ selectedData.children_health_insurance_number_5 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_5 != null && selectedData.children_name_5 != ''">
          5. Gyermek adószám: <b>{{ selectedData.children_tax_number_5 }}</b>
        </b-col>
        <b-col lg="6" class="mt-1" v-if="selectedData.children_name_5 != null && selectedData.children_name_5 != ''">
          5. Gyermek anyja neve: <b>{{ selectedData.children_mother_name_5 }}</b>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Karrier a cégnél:</b>
        </b-col>
        <b-col>
          <table class="table">
            <tr>
              <th>
                <b>Cég</b>
              </th>
              <th>
                <b>Beosztás</b>
              </th>
              <th>
                <b>Kezdés</b>
              </th>
              <th>
                <b>Befejezés</b>
              </th>
            </tr>
            <tr v-for="carrier in carrier_data" :key="carrier">
              <td>{{ carrier.monolit }}</td>
              <td>{{ carrier.work }}</td>
              <td>{{ carrier.work_start }}</td>
              <td>{{ carrier.exit_time }}</td>
            </tr>
          </table>
        </b-col>
        <b-col lg="12" class="justify-content-end d-flex mt-2">
          <button size="sm" class="btn btn-danger" @click="$bvModal.hide('employee_data')">
            Bezár
          </button>
        </b-col>
      </b-row>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
  },
  data() {
    return {
      carrier_data: [],
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Név',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Munkakör',
          field: 'work',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés munkakörre',
          },
        },
        {
          label: 'Telefonszám',
          field: 'phone_number',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés telefonszámra',
          },
        },
        {
          label: 'Fizetés',
          field: 'salary',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés fizetésre',
          },
        },
        {
          label: 'Szakma',
          field: 'profession',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés szakmára',
          },
        },
        {
          label: 'Cég',
          field: 'monolit',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés cégre',
          },
        },
        {
          label: 'Aktív munkavállaló',
          field: 'active',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      selectedData: {},
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-success',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-info',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.loadUserList()
  },
  methods: {
    // eslint-disable-next-line camelcase
    getCarrierData(tax_number) {
      axios({
        method: 'get',
        // eslint-disable-next-line camelcase
        url: `employee/carrier/${tax_number}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.carrier_data = response.data
      })
    },
    archiveThisUser(id) {
      axios({
        method: 'delete',
        url: `employee/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadUserList()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Alkalmazott sikeresen archiválva lett',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    loadUserList() {
      axios({
        method: 'get',
        url: 'employee/archive',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
</style>
